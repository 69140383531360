import { Tenant } from "../../app/main/shared/interfaces/tenant";

export const shippigoConfig: Tenant = {
  name: "shippigo",
  sName: "Shippigo",
  title: "Shippigo - eCommerce",
  tagline: "Welcome to Shippigo!",
  companyName: "Saged Retail Solutions Private Limited",
  origin: "https://app.shippigo.com",
  email: "contact@shippigo.com",
  phone: "9986724291",
  termsPageUrl: "https://www.shippigo.com/terms",
  privacyPageUrl: "https://www.shippigo.com/privacy",
  cancelationAndRefundUrl:
    "https://shippigo.com/cancellation-and-refund-policy",
  landingPageUrl: "https://www.shippigo.com",
  contactPageUrl: "https://shippigo.com/#contact",
  rzpKey: "rzp_live_Eh6ix5HalTvUgM",
  bankingDetails: {
    accountHolderName: "Saged Retail Solutions Private Limited",
    bankName: "HDFC",
    accountNumber: "50200034652151",
    ifscCode: "HDFC0003648"
  },
  lightLogos: {
    main: "https://app.shippigo.com/assets/images/logos/shippigo-by-emiza-white.svg",
    square: "assets/images/logos/shippigo-white.svg"
  },
  darkLogos: {
    main: "https://app.shippigo.com/assets/images/logos/shippigo-by-emiza.svg",
    square: "assets/images/logos/shippigo.svg"
  },
  poweredByLogoUrl:
    "https://app.shippigo.com/assets/images/logos/powered_by_shippigo.png",
  faviconIconUrl: "assets/images/logos/shippigo-favicon.png",
  loaderUrl: "assets/images/logos/loader.gif",
  deliveryManagerUrl: "https://tracking-page-dev.shippigo.com/delivery-manager",
  socialMedia: {
    fb: "https://www.facebook.com/shippigoofficial",
    twitter: "https://twitter.com/shippigo",
    insta: "https://www.instagram.com/shippigo_official",
    whatsapp: ""
  },
  easebuzzEnvironment: "prod"
};
