import { Tenant } from "../../app/main/shared/interfaces/tenant";

export const emizaConfig: Tenant = {
  name: "emiza",
  sName: "Emiza",
  title: "Ship with Emiza",
  tagline: "",
  companyName: "Emiza Supply Chain Services Private Limited",
  origin: "https://ship.emizainc.com",
  email: "contact@emizainc.com",
  phone: "9986724291",
  landingPageUrl: "https://emizainc.com",
  termsPageUrl: "https://emizainc.com/terms-and-conditions",
  privacyPageUrl: "https://emizainc.com/privacy-policy",
  cancelationAndRefundUrl: "https://emizainc.com/refund-cancellation-policy",
  contactPageUrl: "https://emizainc.com/lets-talk",
  rzpKey: "rzp_live_7PsI5gdSN2rHgf",
  bankingDetails: {
    accountHolderName: "Emiza Supply Chain Services Private Limited",
    bankName: "Axis Bank",
    accountNumber: "922020049919634",
    ifscCode: "UTIB0000020"
  },
  lightLogos: {
    main: "https://ship.emizainc.com/assets/images/tenant/emiza/white-logo.png",
    square: "assets/images/tenant/emiza/square-white-logo.png"
  },
  darkLogos: {
    main: "https://ship.emizainc.com/assets/images/tenant/emiza/logo.png",
    square: "assets/images/tenant/emiza/square-logo.png"
  },
  poweredByLogoUrl:
    "https://ship.emizainc.com/assets/images/tenant/emiza/powered-by-emiza.png",
  faviconIconUrl: "assets/images/tenant/emiza/favicon.png",
  loaderUrl: "assets/images/tenant/emiza/loader.svg",
  deliveryManagerUrl: "https://tracking-page-dev.emizainc.com/delivery-manager",
  socialMedia: {
    fb: "https://www.facebook.com/emizainc",
    twitter: "https://twitter.com/Emiza_Inc",
    insta: "https://www.instagram.com/emizainc",
    whatsapp: ""
  },
  easebuzzEnvironment: "prod"
};
